export const LabelKeys = {
  /** HOMEPAGE Values */
  HOME_PAGE_PRODUCT: "PRODUCT OVERVIEW QUADRIFID",

  /** Address Labels */
  ADDRESS_SHIPPING: "Shipping address",
  ADDRESS_INVOICE: "Invoice address",

  ADDRESS_FIELD_COMPANY: "Company",
  ADDRESS_FIELD_FIRST_NAME: "First name",
  ADDRESS_FIELD_LAST_NAME: "Last Name",
  ADDRESS_FIELD_LINE_1: "Address Line 1",
  ADDRESS_FIELD_LINE_2: "Address Line 2",
  ADDRESS_FIELD_CITY: "City",
  ADDRESS_FIELD_POSTAL_CODE: "Postal code",
  ADDRESS_FIELD_COUNTRY: "Country",
  ADDRESS_FIELD_PHONE: "Phone",
  ADDRESS_FIELD_EMAIL: "Email",
  /** Cart page labels */
  CART_ADDRESS_IDENTICAL: "Invoice address is identical to shipping address",
  CART_ADD_NEW: "Add new address",

  CART_TOTAL_HEADING: "Cart total",
  CART_SUBTOTAL: "Subtotal",
  CART_TAX_RATE: "Tax Rate",
  CART_TOTAL: "Total",
  CART_PAYMENT_METHOD: "Payment Method",
  CART_ON_ACCOUNT: "On Account",

  HEADER_PRODUCTS: "Products",
  HEADER_SEARCH: "Search",
  HEADER_LOGIN: "Login",
  HEADER_REGISTER: "Register",
  HEADER_REGISTER_SUB_TEXT: "Haven't ordered with us before?",
  HEADER_FORGOT_PASSWORD: "Forgot your password?",
  HEADER_ACCOUNT: "Your Account",
  HEADER_CUSTOMER_ID: "Customer-ID:",
  HEADER_PROFILE: "Your Profile",
  HEADER_ORDER_REVIEW: "Order review",
  HEADER_LOGOUT: "Logout",

  FOOTER_TEL: "Tel:",
  FOOTER_SERVICE: "Service",
  FOOTER_IMPRESSUM: "Impressum",
  FOOTER_T_N_C: "Terms and Conditions",
  FOOTER_HELP_CENTER: "Help Center",
  FOOTER_ABOUT_US: "About Us",
  FOOTER_ACCOUNT: "Account",
  FOOTER_MY_ACCOUNT: "My Account",
  FOOTER_REGISTER: "Register",
  FOOTER_LOGIN: "Login",
  FOOTER_PAYMENT: "Payment",
  FOOTER_SOCIAL_MEDIA: "Social Media",
  FOOTER_SHIPPING: "Shipping",

  CHECKOUT_SUCCESS_MSG_1: "Congratulation!",
  CHECKOUT_SUCCESS_MSG_2: "You have successfully completed your purchase.",
  CHECKOUT_ORDER_NO: "Your Order-No:",
  CHECKOUT_CONTINUE_SHOPPING: "Continue shopping",
  CHECKOUT_DO_NOT_CLOSE:
    "It may take 1-2 minutes, please do not close the browser",
  CHECKOUT_ORDER_CREATION: "The order creation is in progress",

  REVIEW_CART_HEADING: "Your shopping cart",
  REVIEW_CART_CONTINUE_SHOPPING: "Continue shopping",
  REVIEW_CART_ARTICLE: "Article",
  REVIEW_CART_QUANTITY: "Quantity",
  REVIEW_CART_TOTAL: "Total",
  REVIEW_CART_REMOVE: "Remove",
  REVIEW_CART_CART_TOTAL: "Cart Total",
  REVIEW_CART_SUBTOTAL: "Subtotal",
  REVIEW_CART_TAX: "Tax",
  REVIEW_CART_CHECKOUT: "Checkout",

  TITLE_HOME: "Buy personalised products online",
  TITLE_CART: "Review Cart",
  TITLE_REVIEW_CART: "Cart",
  TITLE_PRIVACY: "About Us",
  TITLE_TERMS: "Terms and Conditions",
  TITLE_PROCESSING_PAYMENT: "Processing Payment",
  TITLE_ORDER_SUCCESSFUL: "Order placed successfully",
};
