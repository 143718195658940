import { LOCALE_VALUES } from "../context/localeContext";

const theme = `query MyTheme {
  logoTheme {
    lightColor {
      hex
    }
    primaryColor {
      hex
    }
    fontSize
    darkColor {
      hex
    }
    accentColor {
      hex
    }
    favIcon {
      url
      title
      size
    }
    uploadLogo {
      title
      url
    }
  }
}`;

const footer = (locale) => `query MyQuery {
  footer(locale:${locale}) {
    contactBlock {
      zipCode
      phoneNumber
      emailId
      countryCode
      country
      companyName
      city
      addressLine2
      addressLine1
    }
    socialMediaBlock {
      socialMediaName
      socialMediaPageLink
      socialMediaLogo {
        url
      }
    }
  }
  logoTheme {
    uploadLogo {
      title
      url
    }
  }
}`;

const carousel = (locale) => `query MyQuery {
  homePage(locale:${locale}) {
    imageCarousel {
      heading1
      heading2
      buttonName
      backgroundImage {
        url
      }
      landingScreen {
        __typename
        ... on ExternalLinkRecord {
          id
          externalUrl
        }
        ... on ProductRecord {
          id
          name
        }
      }
    }
  }
}`;

const header = (locale) => `query MyQuery {
  header(locale:${locale}) {
    id
    quickLinks {
      ... on AboutUsPageRecord {
        id
        title
      }
      ... on PolicyDetailRecord {
        id
        title
      }
    }
    chooseProductsIfAny {
      id
      productId
      name
    }
    defineSubCategories {
      subCategory {
        name
        products {
          id
          productId
          name
        }
        subCategory {
          id
          name
        }
        id
        name
      }
      products {
        id
        productId
        name
      }
      name
      id
    }
  }
  logoTheme {
    uploadLogo {
      title
      url
    }
  }
}`;

const homeProductCard = (locale) => `query MyQuery {
  homePage(locale:${locale}) {
    productCard {
      chooseProduct {
        name
        assets {
          url(imgixParams: {auto: format, q: 75, fit: fill, fill: blur, h: 300, w: 300})
        }
        description
        shortDescription
        id
      }
      cardNumber
    }
  }
}`;
const homePageTeaser = (locale) => `query MyQuery {
  homePage(locale:${locale}) { 
    homePageTeaser {
        id
        title
        description
    }
  }
}`;
const highlightedCarouselCard = (locale) => `query MyQuery {
  homePage(locale:${locale}) { 
    highlightedCarouselCardShowOnHomePage
    highlightedCarouselCardChoose {
      title
      description
      highlightedImage {
        url
      }
      landingScreen {
        id
      }
    }
  }
}
`;
const singleImageCard = (locale) => `query MyQuery {
  homePage(locale:${locale}) {
    singleImgCardShowOnHomePage
    singleImgCardTitle
    singleImgCardDescription1
    singleImgCardDescription2
    buttonName
    singleImgCardImage {
      url
    }
    singleImgCardLandingScreen{
      id
    }
  }
}`;
const fetchProductDetail = (locale) => `query getDetails($id: ItemId!) {
  storefrontConfig:  storefrontConfig {
    storefrontId
  },
  details: allProducts(locale:${locale},
    filter: {
      id: {eq: $id}
    }) {
    artworkDescription
    active
    name
    fulfillerId
    description
    productCatalogDescription
    productId
    productStatus
    productTechnicalSpecifications
    referenceId
    shortDescription
    sku
    taxInformation
    version
    id
    assets {
      url
    }
  }
}`;

const getAttributesByReferenceId = (locale) => `query MyQuery($id: String!) {
  product(locale: ${
    locale || LOCALE_VALUES.english
  }, filter: {referenceId: {eq: $id}}) {
    sku
    attributeList {
      name
      slug
      sortOrder
      visible
      description
      attributeValueType
      key
    }
    referenceId
  }
}
`;

const listAllAttributes = `query MyQuery {
  allAttributes {
    complexAttributeValue {
      id
      rangeList {
        minimum
        maximum
        key
        increment
        id
        createdAt
        updatedAt
      }
      updatedAt
      key
      createdAt
    }
    attributeValueType
    description
    createdAt
    id
    slug
    simpleAttributeValue {
      id
      value
      updatedAt
      mcpValue
      createdAt
      color {
        alpha
        blue
        green
        hex
        red
      }
    }
    name
    mcpKey
    key
    isComputed
    sortOrder
    updatedAt
    visible
  }
}
`;

const fetchLogoUrl = `query fetchFaviconUrl {
  logoTheme {
    favIcon {
      url
    }
  }
}`;

const termsAndCondition = (locale) => `query MyQuery {
  termsAndCondition(locale:${locale}) {
    title
    description
  }
}
`;

const privacyPolicy = (locale) => `query MyQuery {
  impressum(locale:${locale}) {
    description
    title
  }
}
`;

export default {
  carousel,
  footer,
  theme,
  header,
  homeProductCard,
  fetchProductDetail,
  getAttributesByReferenceId,
  listAllAttributes,
  fetchLogoUrl,
  termsAndCondition,
  privacyPolicy,
  homePageTeaser,
  singleImageCard,
  highlightedCarouselCard,
};
